@import "../../layout";

#birthday-lists {
    @include column(center, null, 12px);
    max-width: 748px;
    margin: 0 auto;
    padding: 0px 16px;

    #birthday-lists-add {
        @include center;
    }

    .birthday-lists-item {
        @include row(center, space-between, 8px);
        border-radius: 0.5em;
        padding: 0.5em;

        @include themed() {
            background-color: rgba(t('color3'), 0.9);

            &:hover {
                background-color: rgba(t('color2'), 0.9);
            }
        }
    }
}