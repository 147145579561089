@import "../../layout";

#birthday-form {
    @include column(flex-start, null, 12px);
    width: 100%;

    .birthday-form-label {
        margin-left: 16px;
        font-size: 12px;
    }

    #birthday-form-owner,
    #birthday-form-codes,
    #birthday-form-benefits {
        @include column(flex-start, null, 8px);
    }

    #birthday-form-codes,
    #birthday-form-benefits {
        width: 100%;
    }

    #birthday-form-codes {
        .birthday-form-code {
            @include row(center, space-between, 12px);
        }
    }

    #birthday-form-settings {
        @include row(flex-start, space-evenly, 12px);
        width: 100%;
    }

    #birthday-form-cta {
        @include center;
        padding: 0px 16px;
        width: calc(100% - 32px);
    }
}