@import "../../layout";

#tickets-configuration {
    @include column(null, null, 12px);
    max-width: 748px;
    margin: 0 auto;
    padding: 0px 16px;

    #tickets-configuration-segments {
        @include column(null, null, 12px);

        .form-input-text {
            height: 42px;
            padding: 0px 8px;
        }

        #tickets-configuration-segments-new {
            @include row(center, center, 12px);
        }

        .tickets-configuration-segment {
            @include column(null, null, 12px);
            border-radius: 0.5em;
            padding: 0.5em;

            @include themed() {
                background-color: rgba(t('color3'), 0.45);
            }

            button.close {
                padding: 0px;
                border: 0px;
                background-color: transparent;
                cursor: pointer;

                @include themed() {
                    color: t('color5');

                    &:hover {
                        color: t('link-color');
                    }
                }
            }

            .tickets-configuration-segment-name {
                @include row(center, space-between, 8px);
            }

            .tickets-configuration-batches-new {
                @include row(center, center, 12px);
            }

            .tickets-configuration-batch {
                @include row(center, space-between, 8px);
                border-radius: 0.5em;
                padding: 0.5em;

                @include themed() {
                    background-color: rgba(t('color3'), 0.9);

                    &:hover {
                        background-color: rgba(t('color2'), 0.9);
                    }
                }

                .tickets-configuration-batch-name {
                    width: 100%;
                    cursor: pointer;
                }
            }
        }
    }

    #tickets-configuration-editing-batch {
        @include center;
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        z-index: 2;
        width: 100%;
        height: 100%;

        & > div:first-child {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 3;
        }

        #tickets-configuration-editing-batch-form-click-out {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 3;
        }

        #tickets-configuration-editing-batch-container {
            @include column(null, null, 16px);
            z-index: 4;
            width: 100%;
            max-width: 700px;
            height: 100%;
            max-height: min(300px, 60vh);
            border-radius: 0.5em;
            padding: 1.5em;
            overflow-y: scroll;
            backdrop-filter: blur(5px);
            box-shadow: 0px 0px 9px 3px rgba(#000, 0.4);

            @include themed() {
                background-color: rgba(t('color3'), 0.75);
            }

            #tickets-configuration-editing-batch-name {
                font-size: 20px;
            }

            .tickets-configuration-editing-batch-form-row {
                @include row(center, center, 8px);
                width: 100%;

                .form-input-text {
                    width: calc(100% - 64px);

                    @include themed() {
                        background-color: rgba(t('color2'), 0.75);
                    }
                }
            }

            #tickets-configuration-editing-batch-form-quantity,
            #tickets-configuration-editing-batch-form-price {
                @include column(null, null, 6px);
                width: 100%;
                font-size: 12px;

                .form-input-text {
                    border-radius: 0.5em;
                    height: 40px;
                }
            }

            #tickets-configuration-editing-batch-form-public,
            #tickets-configuration-editing-batch-form-overflow {
                width: calc(50% - 0.5em - 4px);
                margin-left: 0.5em;

                .form-input-checkbox {
                    justify-content: flex-start;
                    gap: 8px;
                    font-size: 12px;
                    line-height: 120%;

                    & > div {
                        transform: scale(0.8);
                    }
                }
            }

            .form-button {
                @include center;
                margin-top: auto;
            }
        }
    }

    #tickets-configuration-editing-batch-form-date {
        @include row(flex-start, null, 12px);
        width: 100%;

        .icon {
            @include themed() {
                color: t('link-color');
            }
        }
    }

    #tickets-configuration-editing-batch-form-date {
        #tickets-configuration-editing-batch-form-date-container {
            width: 100%;

            @media only screen and (min-width: 480px) {
                @include row(center, null, 8px);
            }
    
            @media only screen and (max-width: 480px) {
                @include column(flex-start, null, 8px);
            }

            #tickets-configuration-editing-batch-form-date-start,
            #tickets-configuration-editing-batch-form-date-end {
                @include column(flex-start, null, 4px);
                width: calc(50% - 10px);
                min-width: 220px;
                font-size: 12px;

                .tickets-configuration-editing-batch-form-date-input {
                    @include row(center, null, 2px);
                    font-size: 14px;

                    & > div {
                        position: relative;
                        text-align: center;
                        padding: 4px 12px;
                        cursor: default;

                        @include themed() {
                            background-color: t('color2');
                        }

                        @media only screen and (max-width: 480px) {
                            border-radius: 0.5em;
                        }

                        &:hover {
                            @include themed() {
                                background-color: rgba(t('color1'), 0.5);
                            }
                        }

                        .tickets-configuration-editing-batch-form-date-calendar {
                            position: absolute;
                            top: 28px;
                            left: 0px;
                            z-index: 4;
                            display: none;

                            &.open {
                                display: block;
                                
                                .calendar {
                                    opacity: 0;
                                    backdrop-filter: blur(5px);
                                    animation: open-content 0.4s ease-out forwards;
                                }
                            }

                            .calendar {
                                width: 220px;
                                padding: 4px;
                                border-radius: 0.5em;
                                box-shadow: 0px 0px 9px 3px rgba(#000, 0.2);
                                backdrop-filter: blur(5px);

                                @include themed() {
                                    border: 1px solid rgba(t('color5'), 0.4);
                                    background-color: rgba(t('color3'), 0.8);
                                }
                            }
                        }

                        .tickets-configuration-editing-batch-form-date-time-select {
                            position: absolute;
                            top: 28px;
                            left: 0px;
                            z-index: 4;
                            display: none;
                            max-height: 260px;
                            border-radius: 0.5em;
                            padding: 0px;
                            margin: 0px;
                            overflow: auto;
                            -webkit-overflow-scrolling: touch;
                            box-shadow: 0px 0px 9px 3px rgba(#000, 0.2);
                            backdrop-filter: blur(5px);

                            @include themed() {
                                border: 1px solid rgba(t('color5'), 0.4);
                                background-color: rgba(t('color3'), 0.8);
                            }

                            &.open {
                                display: block;
                                opacity: 0;
                                animation: open-content 0.4s ease-out forwards;
                            }

                            & > div {
                                width: calc(100% - 18px);
                                border-radius: 0.5em;
                                padding: 4px 8px;
                                margin: 1px;

                                &:hover {
                                    @include themed() {
                                        background-color: rgba(t('color1'), 0.4);
                                    }
                                }

                                &.selected {
                                    @include themed() {
                                        background-color: rgba(t('link-color'), 0.6)
                                    }
                                }
                            }
                        }
                    }

                    & > div:first-child {
                        width: 76px;
                    }

                    & > div:last-child {
                        width: 40px;
                    }

                    @media only screen and (min-width: 480px) {
                        & > div:first-child {
                            border-top-left-radius: 0.5em;
                            border-bottom-left-radius: 0.5em;
                        }
    
                        & > div:last-child {
                            border-top-right-radius: 0.5em;
                            border-bottom-right-radius: 0.5em;
                        }
                    }
                }
            }
        }
    }
}